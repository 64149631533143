const projetsData = [
  {
    id: 1,
    type: "photovoltaique",
    images: [
      require("../assets/pv1.jpg"),
      require("../assets/pv2.jpg"),
      require("../assets/2.jpg"),
    ],
    imageAlt: "projet1",
    title: "Titre du projet 1",
    subtitle: "Infos",
    description: "Détails du projet 1...",
  },
  {
    id: 2,
    type: "photovoltaique",
    images: [require("../assets/pv2.jpg"), require("../assets/2.jpg")],
    imageAlt: "projet2",
    title: "Titre du projet 2",
    subtitle: "Infos",
    description: "Détails du projet 2...",
  },
  {
    id: 3,
    type: "photovoltaique",
    images: [
      require("../assets/2.jpg"),
      require("../assets/pv2.jpg"),
      require("../assets/pv1.jpg"),
    ],
    imageAlt: "projet3",
    title: "Titre du projet 3",
    subtitle: "Infos",
    description: "Détails du projet 3...",
  },
  {
    id: 4,
    type: "knx",
    images: [require("../assets/pv1.jpg"), require("../assets/2.jpg"), require("../assets/3.jpg")],
    imageAlt: "projet1",
    title: "Titre du projet 1",
    subtitle: "Infos",
    description: "Détails du projet 1...",
  },
  {
    id: 5,
    type: "knx",
    images: [require("../assets/2.jpg"), require("../assets/3.jpg"), require("../assets/1.jpg")],
    imageAlt: "projet2",
    title: "Titre du projet 2",
    subtitle: "Infos",
    description: "Détails du projet 2...",
  },
  {
    id: 6,
    type: "knx",
    images: [require("../assets/3.jpg"), require("../assets/2.jpg"), require("../assets/1.jpg")],
    imageAlt: "projet3",
    title: "Titre du projet 3",
    subtitle: "Infos",
    description: "Détails du projet 3...",
  },
  {
    id: 7,
    type: "reseau",
    images: [
      require("../assets/1.jpg"),
      require("../assets/if1.jpg"),
      require("../assets/if2.jpg"),
    ],
    imageAlt: "projet1",
    title: "Titre du projet 1",
    subtitle: "Infos",
    description: "Détails du projet 1...",
  },
  {
    id: 8,
    type: "reseau",
    images: [
      require("../assets/if1.jpg"),
      require("../assets/if2.jpg"),
      require("../assets/1.jpg"),
    ],
    imageAlt: "projet2",
    title: "Titre du projet 2",
    subtitle: "Infos",
    description: "Détails du projet 2...",
  },
  {
    id: 9,
    type: "reseau",
    images: [
      require("../assets/if2.jpg"),
      require("../assets/if1.jpg"),
      require("../assets/2.jpg"),
    ],
    imageAlt: "projet3",
    title: "Titre du projet 3",
    subtitle: "Infos",
    description: "Détails du projet 3...",
  },
];

export default projetsData;
